<template>
  <vs-card class="p-5 w-full md:w-1/3 mx-auto md:mt-20">
    <h3 class="text-primary mb-12">Sua sessão está bloqueada</h3>
    <ValidationObserver v-slot="{ invalid }">
      <form>
        <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
          <vs-input
            class="w-full mt-8"
            size="large"
            label-placeholder="E-mail"
            v-model="email"/>
          <span class="text-danger text-sm" v-show="errors[0] ">
            {{ errors[0]  }}
          </span>
        </ValidationProvider>
        <ValidationProvider name="Senha" rules="required|min:6" v-slot="{ errors }">
          <vs-input
            class="w-full mt-10"
            type="password"
            size="large"
            label-placeholder="Senha"
            v-model="password"/>
        <span class="text-danger text-sm" v-show="errors[0] ">
          {{ errors[0]  }}
        </span>
        </ValidationProvider>
        <div class="flex justify-between">
          <router-link to="/login">
            <vs-button type="flat"
              class="mt-8">Você não é {{userInfo.DisplayName}}?</vs-button>
          </router-link>

          <vs-button
            :disabled="invalid"
            @click.prevent="submitForm()"
            class="mt-8">Desbloquear</vs-button>
        </div>
      </form>
    </ValidationObserver>
  </vs-card>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {

  data () {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["token", "userInfo"])
  },
  methods: {
    ...mapActions("auth", ["login"]),

    submitForm () {
      this.$vs.loading();
      return this.login({
        usuario:this.email,
        senha:this.password
      })
        .then(() => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.$onpoint.errorModal((((errors.response || {}).data || {}).error_description) || "Não foi possível realizar o Login.");
        })
        .finally(() => this.$vs.loading.close());
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-lock",
      url: window.location.href
    });
  }
};
</script>
